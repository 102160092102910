import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import Globe from '../components/globe';
import Container from '../components/layout/container';
import Section from '../components/sections/section';
import GraphQLErrorList from '../components/graphql-error-list';
import GlobalStudentsGrid from '../components/students/global-students-grid';
import SEO from '../components/layout/seo';
import Layout from '../containers/layout';

export const query = graphql`
  query GlobalWorksPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    headerBackgroundImage: file(relativePath: { eq: "globe-hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 815, maxWidth: 1169, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    students: allSanityStudent(filter: { hiddenProfile: { ne: true } }) {
      nodes {
        ...StudentPreview
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  globeContainer: {
    backgroundColor: '#0A1420',
  },
  global: {
    alignItems: 'center',
    display: 'block',
  },
  header: {
    color: 'white',
  },
}));

const GlobalTemplate = (props) => {
  const studentGridRef = useRef();
  const { data, errors } = props;
  const site = (data || {}).site;

  const [countryISO, setCountryISO] = React.useState(null);
  const [updateCount, setUpdateCount] = React.useState(0);

  const classes = useStyles();

  const handleViewButton = (iso) => {
    setCountryISO(iso);
    setUpdateCount(updateCount + 1);

    if (studentGridRef.current) {
      scroll({
        top: studentGridRef.current.offsetTop - 75,
        behavior: 'smooth',
      });
    }
  };

  const studentData =
    data.students &&
    data.students.nodes.reduce((acc, { country }) => {
      if (country) {
        const { title, code } = country;
        const index = acc.findIndex((val) => val.title === title);

        if (index >= 0) {
          acc[index].count += 1;
        } else {
          acc.push({ title, code, count: 1 });
        }
      }
      return acc;
    }, []);

  return (
    <Layout
      siteSubtitle="Global View of Student Portfolios"
      siteTitle="Academy of Art University"
      heroImageCaption="Gyungju Do / Illustration"
      headerBackgroundImage={data.headerBackgroundImage ? data.headerBackgroundImage : data.backgroundImageFallback}
    >
      <SEO
        title="Global View of Student Portfolios"
        description="Academy of Art University attracts students from around the world. Explore the amazing work of our students by their country of origin. Find fellow artists in your location!"
        keywords={site.keywords}
      />

      {errors && <SEO title="GraphQL Error" path={props.location.pathname} />}

      <Section alignment="center" globe>
        <Container>
          <div className={classes.globeContainer}>
            <div className={classes.global}>
              <div className={classes.header}>
                <h2>Global View of Student Portfolios</h2>
                <p>
                  Over the years, artists and designers from more than 130 countries have made Academy of Art University
                  their home.
                </p>
                <p>
                  The students featured at Spring Show are from all over the world. From Asia and Alaska. Hayward and
                  Ukraine. Turkey and the Tenderloin. They are different in countless ways—in race, religion, political
                  leaning, gender, and more. We embrace these differences. We always have. Each is welcome here.
                </p>
                <p>
                  This no-barriers approach makes Academy of Art University one of the most diverse artistic educational
                  communities in the world. Our diversity opens the world to our students. It gives them freedom to
                  express feelings they thought were only their own.
                </p>
                <p>
                  Our diversity gives students a place to truly be themselves. It gives them the confidence to express
                  their creativity in ways that they dared not before. And to become extraordinary artists, designers
                  and creators.
                </p>
                <p>Explore their work on this site, and on the globe below. </p>
              </div>
              <Globe viewPortfolio={handleViewButton} studentData={studentData} />
            </div>
          </div>
        </Container>
      </Section>

      {data.students && (
        <Section color="black">
          <GlobalStudentsGrid
            students={data.students.nodes}
            iso={countryISO}
            ref={studentGridRef}
            updateCountryFilter={countryISO}
            updateCounter={updateCount}
          />
        </Section>
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
    </Layout>
  );
};

export default GlobalTemplate;
