import React from 'react';
import Container from '../components/layout/container';
import Section from '../components/sections/section';
import GraphQLErrorList from '../components/graphql-error-list';
import ProjectsGrid from '../components/projects/projects-grid';
import SEO from '../components/layout/seo';
import Layout from '../containers/layout';
import typographyStyles from '../styles/typography.module.css';
import { graphql } from 'gatsby';
import IndustryGrid from '../components/sections/industry-grid';
import { mapEdgesToNodes, sortByTitle } from '../lib/helpers';

export const query = graphql`
  query AllProjectsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    headerBackgroundImage: file(relativePath: { eq: "Abby-Zeng-Hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 815, maxWidth: 1169, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    schools: allSanitySchool {
      nodes {
        ...SchoolPreview
      }
    }
    hiringCompanies: allSanityHiringCompany {
      nodes {
        ...HiringCompanies
      }
    }
    projects: allSanityProject {
      edges {
        node {
          ...ProjectPreview
        }
      }
    }
  }
`;

const ProjectTemplate = (props) => {
  // console.log('ProjectTemplate props: ', props);
  const { data, errors } = props;
  const site = (data || {}).site;
  return (
    <Layout
      siteSubtitle="Collaborative Projects"
      siteTitle="Academy of Art University"
      heroImageCaption="Yanghua (Abby) Zeng / Fashion"
      headerBackgroundImage={data.headerBackgroundImage ? data.headerBackgroundImage : data.backgroundImageFallback}
    >
      <SEO
        title="Collaborative Projects"
        description="Our competitive cross-sectional classes with industry giants like VW Group, General Motors, Stellantis, and NASA mimic real-world professional scenarios and feedback that prepare our students for rewarding careers in the industry..."
        keywords={site.keywords}
      />
      {errors && <SEO title="GraphQL Error" path={props.location.pathname} />}
      {data.projects && (
        <Section color="black" flush>
          <ProjectsGrid showFilters showPagination projects={sortByTitle(mapEdgesToNodes(data.projects))} />
        </Section>
      )}
      <Section color="dark" alignment="center">
        <Container narrower>
          <h1 className={typographyStyles.responsiveTitle1}>Collaboration Pros</h1>
          <p className={typographyStyles.paragraph}>
            At any given moment, dozens of collaborations are happening at the Academy. From corporate-sponsored courses
            with companies like Pixar, NASA, and General Motors designing games, cars, and products, to a group of
            students on campus and on zoom working in VR to design our world in 2040.
          </p>
          <p className={typographyStyles.paragraph}>
            In each case, students learn to collaborate across disciplines, solve problems creatively in teams, adapt to
            changing circumstances, and persist in the face of challenges.
          </p>
        </Container>
      </Section>
      <Section>
        <IndustryGrid hiringCompanies={data.hiringCompanies.nodes} limit="8" />
      </Section>
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
    </Layout>
  );
};

export default ProjectTemplate;
