import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import BlockContent from '../block-content';
import FeaturedProjectsGrid from '../projects/featured-projects-grid';
import ProjectsGrid from '../projects/projects-grid';
import Container from '../layout/container';
import Section from '../sections/section';
import { mapEdgesToNodes, sortByName, sortByTitle } from '../../lib/helpers';

const MAX_RESULTS = 999;

/*
 * see data/fragments/PageContent
 */
const SectionProjectsGrid = ({ section }) => {
  const {
    _key,
    _rawIntro,
    _rawOutro,
    backgroundColor,
    gridStyle,
    showPagination,
    linkOverride,
    showFilters,
    school,
    projects: selectedProjects,
    limitResults,
    narrowWidth,
    schoolForStudents,
  } = section;

  const limit = limitResults && limitResults > 0 ? limitResults : MAX_RESULTS;

  let GridComponent = ProjectsGrid;

  switch (gridStyle) {
    // Add other grid types here
    case 'featured-projects-grid':
      GridComponent = FeaturedProjectsGrid;
      break;
  }

  return (
    <StaticQuery
      key={_key}
      query={allProjectsQuery}
      render={({ allProjects, allStudents }) => {
        const allProjectsSorted = sortByTitle(mapEdgesToNodes(allProjects));
        const allStudentsSorted = sortByName(mapEdgesToNodes(allStudents));

        // keep only the students in the selected schoolForStudents
        const allStudentsInSchoolForStudents = schoolForStudents
          ? allStudentsSorted.filter((student) => student.school && student.school._id === schoolForStudents._id)
          : null;
        /*
         * Four options to display 'projects' in this Projects Grid via the CMS:
         *
         * 1. All Featured Projects in the selected school ('school' field)
         * 2. Individually selected: ('projects' field)
         *      a. Projects - and / or -
         *      b. Student profiles
         * 3. All Student profiles for the selected school ('schoolForStudents' field)
         * 4. All Projects if none of the above were selected in the CMS
         *
         */
        let isDisplayAllFeaturedProjectsForSchool = false;
        let isDisplayIndividuallySelectedProjects = false;
        let isDisplayAllStudentProfilesForSchool = false;
        let isDisplayAllProjects = false;
        let projectList = null;
        if (school && school.featuredProjects.length) {
          isDisplayAllFeaturedProjectsForSchool = true;
          projectList = school.featuredProjects;
        } else if (selectedProjects && selectedProjects.length) {
          isDisplayIndividuallySelectedProjects = true;
          projectList = selectedProjects;
        } else if (schoolForStudents && allStudentsInSchoolForStudents && allStudentsInSchoolForStudents.length) {
          isDisplayAllStudentProfilesForSchool = true;
          projectList = allStudentsInSchoolForStudents;
        } else {
          isDisplayAllProjects = true;
          projectList = allProjectsSorted;
        }

        // limit projects / profiles to display
        const projects = [...projectList].slice(0, limit);

        return (
          <div style={{ backgroundColor }}>
            {_rawIntro && (
              <Section color={backgroundColor} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawIntro} />
                </Container>
              </Section>
            )}
            <GridComponent
              projects={projects}
              showPagination={showPagination}
              showFilters={showFilters}
              linkOverride={linkOverride}
            />

            {_rawOutro && (
              <Section color={backgroundColor} alignment="center">
                <Container narrower={narrowWidth}>
                  <BlockContent blocks={_rawOutro} />
                </Container>
              </Section>
            )}
          </div>
        );
      }}
    />
  );
};

export default SectionProjectsGrid;

const allProjectsQuery = graphql`
  query AllProjectsQuery {
    allProjects: allSanityProject {
      edges {
        node {
          ...ProjectPreview
        }
      }
    }
    allStudents: allSanityStudent {
      edges {
        node {
          ...StudentPreview
        }
      }
    }
  }
`;
